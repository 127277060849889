<template>
  <div style="width: 100%">
    <v-row align="stretch">
      <v-col class="d-flex justify-center align-center">
        <v-card
          :style="
            'width:' + 100 / KPIsObject.length + '%; border:1px solid #51627c;'
          "
          v-for="(item, index) in KPIsObject"
          :key="index"
          class="ma-2"
          max-width="800px"
        >
          <v-card-subtitle class="mt-4 text-subtitle-1 font-weight-medium">{{
            item.header
          }}</v-card-subtitle>
          <v-card-title class="text-h4 mt-1">{{ item.body }}</v-card-title>
          <v-divider class="mr-3 ml-3"></v-divider>
          <v-card-actions class="text-subtitle-2 font-weight-light ml-2">{{
            item.footer
          }}</v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col class="d-flex justify-center align-center mt-0 pt-0"
        >{{ KPIsObject[0].source }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import FormsInputsAndControls from '@/components/formsInputsAndControls'

export default {
  name: "KPIs",
  data: () => ({
    var1: "val1",
    var2: "val2",
    array: [],
    object: {},
  }),
  components: {
    // FormsInputsAndControls
  },
  computed: {},
  props: {
    model: null,
    KPIsObject: null,
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    // this.$emit('mainComponentLoaded', this.modelName, this.subHeader, this.searches, '')
  },
  methods: {
    test() {
      // console.log('this is a test')
    },
  },
  watch: {
    selectedItem: {
      handler() {
        // this.$emit('selectedItem', this.selectedItem)
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
