<template>
  <v-container fluid fill-height>
    <div class="text-center">
      <v-dialog v-model="customThemesDialogue" max-width="700px" scrollable>
        <v-card style="height: 75vh">
          <v-toolbar
            :style="
              'max-height: 60px; ' +
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6"
          >
            <v-toolbar-title>Select Themes</v-toolbar-title>
          </v-toolbar>
          <!-- loading UX -->
          <v-card-actions
            v-if="loadingThemes"
            tile
            elevation="0"
            class="progress"
          >
            <div class="progressText">
              <v-progress-circular
                :size="200"
                :width="3"
                color="#51627C"
                indeterminate
                >Loading Themes
              </v-progress-circular>
            </div>
          </v-card-actions>
          <v-card-text v-else class="pr-2">
            <v-card-actions v-if="$store.state.isGroupAdmin">
              <v-spacer />
              <v-btn
                color="error"
                title="This only available for Group Administrators"
                variant="elevated"
                @click="resetThemeOrder()"
                :loading="resettingThemeOrder"
                prepend-icon="mdi-reload"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                reset order
              </v-btn>
            </v-card-actions>
            <!-- list of themes to go in here -->
            <v-list>
              <v-list-item style="height: 50px !important">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-checkbox
                      v-model="selectAll"
                      label="Select All"
                      @change="selectAllCustomThemes()"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-list-item>
              <Draggable
                v-model="availableThemes"
                @end="updateOrder"
                @start="startDrag"
                item-key="id"
                index="index"
                ><template #item="{ element, index }">
                  <v-list-item>
                    <div class="d-flex" style="height: 55px !important">
                      <v-btn
                        :id="'draggable_' + index"
                        class="drag-handle"
                        variant="flat"
                        icon
                        aria-describedby="indicator-position-change-instructions"
                        aria-label="drag to re-order"
                        label="drag to re-order"
                        title="drag to re-order"
                        @keydown="updateOrderByKey($event, index)"
                      >
                        <v-icon>mdi-drag</v-icon>
                      </v-btn>
                      <v-checkbox
                        v-model="selectedThemes"
                        :value="{ themeID: element.id }"
                        :label="element.title"
                        class="themeCheckbox"
                      >
                      </v-checkbox>
                    </div>
                  </v-list-item>
                </template>
              </Draggable>
            </v-list>
          </v-card-text>
          <v-divider v-if="!loadingThemes"></v-divider>
          <v-card-actions v-if="!loadingThemes">
            <v-btn
              tile
              variant="elevated"
              color="error"
              @click="closeThemeSelector()"
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :disabled="!selectedThemes.length"
              id="themesSelectedButton"
              variant="elevated"
              color="primary"
              @click="comparatorSelection(selectedReport, true)"
              aria-label="next"
            >
              next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-layout row wrap>
      <!-- the parent card/tile -->
      <v-card tile style="width: 100%">
        <v-card-title id="pageTitle" class="text-left"
          ><h1 class="h1">Reports</h1></v-card-title
        >
        <div class="favPubButtonContainer">
          <v-btn
            v-if="
              this.$store.state.config.siteConfig.is_public_site &&
              this.$store.state.isGroupAdmin
            "
            color="warning"
            class="ma-3 white--text"
            tile
            title="click to view your public reports"
            @click="savedReportsDialogue = 1"
            aria-label="Public Reports"
          >
            Public Reports
            <v-icon right dark tile> mdi-chart-bar-stacked</v-icon>
          </v-btn>
          <v-btn
            append-icon="mdi-heart-outline"
            color="info"
            class="ma-3 white--text"
            tile
            title="click to view your favourite reports"
            @click="savedReportsDialogue = 0"
            aria-label="Favourites"
          >
            Favourites
            <template v-slot:append>
              <v-icon></v-icon>
            </template>
          </v-btn>
          <!-- dialogue with a list of favourites or public reports -->
          <SavedReportsList
            v-model:savedReportsDialogue="savedReportsDialogue"
          />
        </div>
        <v-card-actions>
          <v-divider></v-divider>
        </v-card-actions>
        <v-card-actions v-if="!pageLoading">
          <!-- search card/tile -->
          <v-card-text>
            <v-autocomplete
              id="search"
              no-filter
              v-model="userSelectedSearchCustomArea"
              :items="searchCustomAreas"
              :loading="customAreasSearchLoading"
              v-model:search="search"
              loader-height="2"
              item-title="name"
              item-value="id"
              label="Search for Custom Areas"
              placeholder="Start typing to Search"
              prepend-icon="mdi-magnify"
              return-object
              :no-data-text="
                customAreasSearchLoading ? 'Searching' : 'No Results'
              "
              style="max-width: 800px"
              variant="outlined"
              density="compact"
              rounded="0"
              :tabindex="0"
              :autofocus="true"
            >
              <template #item="{ item, props }">
                <div
                  v-if="typeof item.raw === 'object' && 'divider' in item.raw"
                />
                <v-list-subheader
                  v-else-if="
                    typeof item.raw === 'object' && 'header' in item.raw
                  "
                  :title="item.raw.header"
                />
                <v-list-item
                  v-else
                  v-bind="props"
                  :label="item.title"
                  :value="item.value"
                  class="pl-10"
                  @click="whichType(item.raw)"
                >
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-card-text>
        </v-card-actions>
        <!-- expansion panels -->
        <v-card-actions v-if="pageLoading">
          <div class="progressCategory">
            <v-progress-circular
              :size="180"
              :width="3"
              color="#51627C"
              indeterminate
            >
              Loading Categories
            </v-progress-circular>
          </div>
        </v-card-actions>
        <v-card-text
          :style="'height:' + this.height + 'px; overflow: auto;'"
          v-else
        >
          <v-expansion-panels
            id="reportCategories"
            focusable
            v-model="openedPanel"
          >
            <v-expansion-panel
              v-for="(categoryItem, i) in filterCategories"
              :key="i"
              :aria-expanded="openedPanel === i ? true : false"
            >
              <v-expansion-panel-title
                @click="
                  selectedCategory = categoryItem;
                  userSelectedSearchCustomArea = null;
                "
                :id="i"
                tabindex="1"
              >
                {{ categoryItem.name }}
              </v-expansion-panel-title>
              <v-expansion-panel-text
                v-if="customAreasLoaded"
                style="width: 100%; max-width: 900px !important"
              >
                <v-data-table
                  width="300px"
                  id="catAreas"
                  :loading="!customAreasLoaded"
                  :headers="headers"
                  :items="customAreas[selectedCategory.id]"
                  :items-per-page="10"
                  :search="search"
                  class="elevation-0 text-left"
                  style="width: 100%"
                >
                  <template v-slot:[`item.viewSummary`]="{ item }">
                    <v-btn
                      size="small"
                      color="success"
                      tile
                      @click="comparatorSelection(item)"
                      id="summaryReport"
                      title="click to generate a summary report"
                      aria-label="click to generate a summary report"
                      label="click to generate a summary report"
                    >
                      Summary Report
                    </v-btn>
                  </template>
                  <template v-slot:[`item.buildCustom`]="{ item }">
                    <v-btn
                      :disabled="false"
                      size="small"
                      color="success"
                      tile
                      @click="buildCustomReport(item)"
                      id="buildCustomReport"
                      title="click to generate a custom report"
                      aria-label="click to generate a custom report"
                      label="click to generate a custom report"
                    >
                      Build Custom Report
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-text>
              <v-expansion-panel-text v-else style="width: 100%">
                <div class="smallSpinner mt-3">
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="#51627C"
                    indeterminate
                    >Loading
                  </v-progress-circular>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-layout>
    <!-- comparator dialog -->
    <SearchAreasFullscreen
      v-model:dialog="comparatorDialog"
      :primaryComparitor="selectedReport"
      :maximumSelectionLimit="2"
      :minimumSelectionLimit="2"
      @next="next"
    />
    <!--dialogue giving user an option for either custom or summary report-->
    <div class="text-center">
      <v-dialog v-model="reportsDialogue" width="500">
        <v-card>
          <v-toolbar
            :color="this.$store.state.config.siteConfig.toolbar_colour"
            dark
            class="text-h5 text-center"
            max-height="64px"
          >
            <v-spacer>
              <v-toolbar-title v-if="chosenArea"
                >{{ chosenArea.name }}
              </v-toolbar-title>
            </v-spacer>
          </v-toolbar>
          <v-card-actions class="pa-5">
            <v-btn
              color="success"
              tile
              variant="elevated"
              @click="comparatorSelection(chosenArea)"
              id="summaryReport"
              aria-label="Summary Report"
            >
              Summary Report
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              tile
              variant="elevated"
              @click="buildCustomReport(chosenArea)"
              aria-label="Build Custom Report"
            >
              Build Custom Report
            </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="error"
              tile
              variant="elevated"
              @click="reportsDialogue = false"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import SearchAreasFullscreen from "@/components/SearchAreasFullscreen";
import SavedReportsList from "@/components/SavedReportsList.vue";
import Draggable from "vuedraggable";

export default {
  name: "ReportsPage",
  data: () => ({
    selectedThemesClone: null,
    publicReports: false,
    selectAll: false,
    customThemesDialogue: false,
    availableThemes: [],
    availableThemesClone: [],
    loadingThemes: true,
    pageLoading: true,
    search: null,
    userSelectedSearchCustomArea: null,
    allCustomAreasList: null,
    categoryWithAreas: [],
    searchCustomAreas: [],
    customAreasSearchLoading: false,
    openedPanel: null,
    filterCategories: null,
    headers: [
      {
        title: "Custom Area",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        align: "center",
        sortable: false,
        value: "viewSummary",
      },
      {
        align: "center",
        sortable: false,
        value: "buildCustom",
      },
    ],
    customAreas: [],
    customAreasLoaded: false,
    categories: [],
    selectedCategory: null,
    reportsDialogue: false,
    chosenArea: null,
    comparatorDialog: false,
    selectedReport: null,
    idleTimeout: null,
    savedReportsDialogue: false,
    resettingThemeOrder: false,
  }),
  components: {
    SearchAreasFullscreen,
    SavedReportsList,
    Draggable,
  },
  computed: {
    height() {
      return (
        window.innerHeight -
        document.querySelector("#appBar").getBoundingClientRect().bottom -
        225
      );
    },
    reportType: {
      get() {
        return this.$store.state.reportType;
      },
      set(value) {
        this.$store.commit("setReportType", value);
      },
    },
    reportDownloading: {
      get() {
        return this.$store.state.reportDownloading;
      },
      set(value) {
        this.$store.commit("setReportDownloading", value);
      },
    },
    reportDownloaded: {
      get() {
        return this.$store.state.reportDownloaded;
      },
      set(value) {
        this.$store.commit("setReportDownloaded", value);
      },
    },
    defaultReportObject: {
      get() {
        return this.$store.state.defaultReportObject;
      },
      set(value) {
        this.$store.commit("setDefaultReportObject", value);
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    reportID: {
      get() {
        return this.$store.state.reportID;
      },
      set(value) {
        this.$store.commit("setReportID", value);
      },
    },
    viewReport: {
      get() {
        return this.$store.state.viewReport;
      },
      set(value) {
        this.$store.commit("setViewReport", value);
      },
    },
    comparatorsList: {
      get() {
        return this.$store.state.comparatorsList;
      },
      set(value) {
        this.$store.commit("setComparatorsList", value);
      },
    },
    selectedComparators: {
      get() {
        return this.$store.state.selectedComparators;
      },
      set(value) {
        this.$store.commit("setSelectedComparators", value);
      },
    },
    reportMapPrimaryAreaCode: {
      get() {
        return this.$store.state.reportMapPrimaryAreaCode;
      },
      set(value) {
        this.$store.commit("setReportMapPrimaryAreaCode", value);
      },
    },
    selectedThemes: {
      get() {
        return this.$store.state.selectedThemes;
      },
      set(value) {
        this.$store.commit("setSelectedThemes", value);
      },
    },
    searchResults: {
      get() {
        var result = {};
        var i = 0;
        for (const category of this.filterCategories) {
          for (const area of this.searchCustomAreas) {
            if (area.custom_area_category_id === null && i === 1) {
              // Uncategorised
              if (typeof result[category.id] !== "undefined") {
                result[category.id].results.push(area);
              } else {
                category.results = [area];
                result[category.id] = category;
              }
            } else if (area.custom_area_category_id === category.id) {
              if (typeof result[category.id] !== "undefined") {
                result[category.id].results.push(area);
              } else {
                category.results = [area];
                result[category.id] = category;
              }
            }
          }
          i++;
        }
        // kinda hacky but we only need one result as this computed property contains all the results now instead
        this.getFirstSearchedArea();
        return Object.values(result);
      },
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    if (this.reportDownloading) {
      this.viewReportDialog = true;
      this.viewReport = true;
    }
    this.getDefaultReportID();
    this.getFilterCategories();
  },
  methods: {
    updateOrderByKey(event, index) {
      this.selectedThemesClone = JSON.parse(
        JSON.stringify(this.selectedThemes),
      );
      var direction = false;
      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }
      if (direction) {
        this.availableThemes = this.reorderArray(
          this.availableThemes,
          index,
          direction,
        );
        this.selectedThemes = this.selectedThemesClone;
        if (direction == "up") {
          setTimeout(() => {
            document
              .getElementById(
                index - 1 < 0
                  ? "draggable_" + index
                  : "draggable_" + (index - 1),
              )
              .focus();
          }, 0);
        } else {
          setTimeout(() => {
            document
              .getElementById(
                index + 1 > this.availableThemes.length - 1
                  ? "draggable_" + index
                  : "draggable_" + (index + 1),
              )
              .focus();
          }, 0);
        }
      }
    },
    reorderArray(arr, index, direction) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      if (direction !== "up" && direction !== "down") {
        return arr;
      }
      if (direction === "up" && index === 0) {
        return arr;
      }
      if (direction === "down" && index === arr.length - 1) {
        return arr;
      }
      const elementToMove = arr.splice(index, 1)[0];
      if (direction === "up") {
        arr.splice(index - 1, 0, elementToMove);
      } else if (direction === "down") {
        arr.splice(index + 1, 0, elementToMove);
      }
      return arr;
    },
    startDrag() {
      this.selectedThemesClone = JSON.parse(
        JSON.stringify(this.selectedThemes),
      );
    },
    updateOrder() {
      this.$nextTick(() => {
        this.selectedThemes = this.selectedThemesClone;
      });
    },
    whichType(area) {
      this.chosenArea = area;
      this.reportsDialogue = true;
    },
    next(val) {
      this.selectedComparators = [];
      this.comparatorsList = val;
      this.comparatorsList.unshift(this.selectedReport);
      for (var i = 0; i < this.comparatorsList.length; i++) {
        this.selectedComparators.push(this.comparatorsList[i].area_code);
      }
      this.viewReport = true;
    },
    getDefaultReportID() {
      this.$axios
        .get("/default-report")
        .then(
          function (response) {
            // handle success
            this.defaultReportObject = response.data;
            this.reportID = this.defaultReportObject.id;
            this.getCategories();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get default report ID",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    // Gets categories, including all & uncategorised
    getCategories() {
      this.$axios
        .get("/custom-area-categories")
        .then(
          function (response) {
            // handle success
            this.categories = response.data;
            this.pageLoading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get all clients",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getFirstSearchedArea() {
      this.searchCustomAreas = [this.searchCustomAreas[0]];
    },
    getFilterCategories() {
      this.filterCategoriesLoaded = false;
      this.$axios
        .get("/custom-area-filter-categories")
        .then(
          function (response) {
            // handle success
            this.filterCategories = response.data;
            this.filterCategoriesLoaded = true;
            this.pageLoading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get area-filter-categories",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
            this.pageLoading = false;
          }.bind(this),
        );
    },
    getCustomAreasByCategory() {
      this.customAreasLoaded = false;
      if (typeof this.customAreas[this.selectedCategory.id] === "undefined") {
        var apiUrl;
        // Get different custom areas depending on category filter choice
        switch (this.selectedCategory.name) {
          case "All Categories":
            apiUrl = "/custom-areas";
            break;
          case "Uncategorised":
            apiUrl = "/uncategorised-custom-areas";
            break;
          default:
            apiUrl = "/custom-areas-by-category/" + this.selectedCategory.id;
        }
        this.$axios
          .get(apiUrl)
          .then(
            function (response) {
              // handle success
              this.customAreas[this.selectedCategory.id] = response.data;
              this.customAreasLoaded = true;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get custom areas",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      } else {
        this.customAreasLoaded = true;
      }
    },
    rearrangeThemes(availableThemes, selectedThemes) {
      let rearrangedArray = [];

      // Create a map to store the index of each ID in availableThemes
      let idIndexMap = new Map();
      availableThemes.forEach((item, index) => {
        idIndexMap.set(item.id, index);
      });

      // Sort selectedThemes based on the index of IDs in availableThemes
      selectedThemes.forEach((item) => {
        let index = idIndexMap.get(item.themeID);
        if (index !== undefined) {
          rearrangedArray[index] = item;
        }
      });

      return rearrangedArray.filter((value) => Object.keys(value).length !== 0);
    },
    comparatorSelection(report, customReport = false) {
      if (!customReport) {
        this.selectedThemes = [];
        this.selectAll = false;
      } else {
        this.selectedThemes = this.rearrangeThemes(
          this.availableThemes,
          this.selectedThemes,
        );
      }
      report.area_code = "CSTM" + report.id;
      report.area_name = report.name;
      report.selected = true;
      this.selectedReport = report;
      this.reportMapPrimaryAreaCode = report.id;
      this.selectedReport.customAreaID = report.id;
      this.comparatorDialog = true;
      this.reportsDialogue = false;
      this.customThemesDialogue = false;
      this.reportDownloaded = false;
      this.reportDownloading = false;
      this.reportType = customReport ? "Custom" : "Summary";

      // check if the order of the themes has changed
      for (let i = 0; i < this.availableThemesClone.length; i++) {
        if (this.availableThemesClone[i].id !== this.availableThemes[i].id) {
          this.saveThemeOrder();
          break;
        }
      }
    },
    buildCustomReport(area) {
      this.selectedReport = area;
      this.reportMapPrimaryAreaCode = area.id;
      this.customThemesDialogue = true;
      this.selectedThemes = [];
      this.selectAll = false;
      this.reportsDialogue = false;

      if (!this.availableThemes.length) {
        this.loadingThemes = true;
        this.$axios
          .get("custom-report-themes")
          .then((response) => {
            this.availableThemes = response.data;
            this.availableThemesClone = JSON.parse(
              JSON.stringify(this.availableThemes),
            );
            this.loadingThemes = false;
          })
          .catch((error) => {
            this.loadingThemes = false;
            console.log(error);
          });
      }
    },
    saveThemeOrder() {
      this.$axios.post("save-report-themes-order", {
        theme_ids: this.availableThemes.map((theme) => theme.id),
      });
    },
    selectAllCustomThemes() {
      if (this.selectAll) {
        this.selectedThemes = this.availableThemes.map((theme) => ({
          themeID: theme.id,
        }));
      } else {
        this.selectedThemes = [];
      }
    },
    resetThemeOrder() {
      this.resettingThemeOrder = true;

      this.$axios
        .get("reset-report-themes-order")
        .then((response) => {
          // set themes
          this.availableThemes = response.data;
          this.availableThemesClone = JSON.parse(
            JSON.stringify(this.availableThemes),
          );
        })
        .catch((error) => {
          console.error(error);
          this.emit.emit("systemMessage", {
            title: "Failed to reset the order",
            message: error.response.data.message,
            timeout: 4000,
            colour: "error",
          });
        })
        .finally(() => {
          this.resettingThemeOrder = false;
        });
    },
    // Reset vars for theme selector on close
    closeThemeSelector() {
      this.customThemesDialogue = false;
      this.selectAll = false;
    },
    fetchCustomAreas() {
      // to search pass the special characters through the URL we need to encode string
      let searchString = encodeURIComponent(this.search.replace("%", "\\%"));

      this.allCustomAreasList = [];
      this.customAreasSearchLoading = true;
      this.$axios
        .get("/custom-areas/" + searchString + "/" + 1)
        .then((response) => {
          // var customAreas = response.data;
          this.searchCustomAreas = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.customAreasSearchLoading = false;
        });
    },
  },
  watch: {
    customThemesDialogue() {
      if (!this.customThemesDialogue) {
        this.selectAll = false;
        // delay this else it's a bit jarring ($nextTick is too quick)
        setTimeout(() => {
          this.availableThemes = [];
        }, 500);
      }
    },
    selectedCategory: {
      handler() {
        if (this.selectedCategory) {
          this.getCustomAreasByCategory();
        }
      },
      deep: true,
    },
    selectedThemes() {
      // The select all checkbox will automatically be checked if all themes are selected
      if (
        this.availableThemes.length > 0 &&
        this.selectedThemes.length === this.availableThemes.length
      ) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    search(val) {
      clearTimeout(this.idleTimeout);

      this.idleTimeout = setTimeout(() => {
        if (val) {
          this.fetchCustomAreas();
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
.favPubButtonContainer {
  position: absolute;
  top: 0px;
  right: 0px;
}
.subResults {
  padding: 5px;
  font-size: 16px !important;
  margin-left: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.subResults:hover {
  background-color: #dedbdb;
}

.progressCategory {
  position: relative;
  text-align: center;
  top: 15vh;
  width: 30vw;
  height: 60vh;
  left: 35vw;
}

.smallSpinner {
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 300px;
}

.progress {
  margin-top: 150px;
  width: 100%;
}

.drag-handle {
  cursor: move !important;
  height: 53px !important;
  width: 53px !important;
}

.v-list-subheader__text {
  font-weight: 600;
  color: black;
}
</style>
