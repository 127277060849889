<template>
  <v-app
    id="inspire"
    class=""
    v-if="config.siteConfig.hasOwnProperty('page_title')"
    :style="loggedIn ? 'background-color: #ffffff!important;' : ''"
  >
    <ReportViewer />
    <v-navigation-drawer
      v-if="loggedIn"
      v-model="drawer"
      app
      :style="!loggedIn ? 'display:none' : null"
      width="400"
    >
      <v-list-item v-if="loggedIn" link class="text-left pa-4">
        <!--   Public site  -->
        <div v-if="config.siteConfig.is_public_site">
          <v-list-item-title class="text-h6">
            {{ this.config.customClientConfig.client_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ this.config.siteConfig.tag_line }}
          </v-list-item-subtitle>
        </div>
        <!--  normal site  -->
        <span v-else>
          <v-list-item-title class="text-h6">
            {{ this.config.userProfile.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="pt-1">
            {{ this.config.userProfile.email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="pt-4">
            {{ this.config.customClientConfig.client_name }}
          </v-list-item-subtitle>
        </span>
        <!-- Show client selector if there is more than one choice -->
        <v-list-item-subtitle v-if="otherClientsCount">
          <br />
          <v-autocomplete
            v-model="switchClient"
            :items="config.userConfig.otherClients"
            placeholder="Switch to a different account"
            id="switchClient"
            no-data-text="No clients found"
            item-title="client_name"
            return-object
            focused
            variant="outlined"
            single-line
            density="compact"
            rounded="0"
            @update:modelValue="switchToDifferentClient()"
          ></v-autocomplete>
        </v-list-item-subtitle>
        <v-list v-if="config.otherSites.length">
          <v-list-subheader>You can also visit these sites:</v-list-subheader>
          <div
            v-for="(item, i) in config.otherSites"
            :key="i"
            class="otherLinks ml-5 py-2"
            @click="visitSite(item.url)"
          >
            {{ item.name }}
          </div>
        </v-list>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav lines="one" id="navList" class="text-left moduleList">
        <v-list-item
          v-for="item in config.userConfig.modules"
          link
          :key="item.slug"
          :id="item.label"
          @click="router(item.route)"
          tabindex="0"
          :title="item.label"
          class="mb-0"
        >
          <template v-slot:prepend>
            <v-icon :icon="'mdi-' + item.icon"></v-icon>
          </template>
          <template v-slot:append v-if="item.ocsiOnly">
            <OcsiOnlyChip></OcsiOnlyChip>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <template v-slot:append>
        <div>
          <v-list nav lines="one" class="text-left extraModuleList">
            <v-list-item
              v-if="uatEnvironment"
              link
              :href="pilotBugTrackerLink"
              target="_blank"
              class="mb-0"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-face-agent"></v-icon>
              </template>
              <v-list-item-title>Bug reporter</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="this.config.siteConfig.site_country !== 'aus'"
              class="mb-0"
              link
              href="https://localinsight.org/blog/"
              target="_blank"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-newspaper-variant-outline"></v-icon>
              </template>
              <v-list-item-title>News</v-list-item-title>
            </v-list-item>
            <v-list-item
              id="help-centre-item"
              class="mb-0"
              link
              :href="`https://${helpCenterLink}`"
              target="_blank"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-lifebuoy"></v-icon>
              </template>
              <v-list-item-title>Help Centre</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              href="https://local-insight.zendesk.com/hc/en-gb/articles/14661041129885-Contact-us"
              target="_blank"
              v-if="!config.userProfile.is_public_site_user_profile"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-human-greeting-proximity"></v-icon>
              </template>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="config.siteConfig.site_country !== 'aus'"
              link
              href="https://localinsight.org/accessibility-statement/"
              target="_blank"
              aria-label="accessibility statement"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-human"></v-icon>
              </template>
              <v-list-item-title>Accessibility Statement</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!config.userProfile.is_public_site_user_profile"
              @click="logout"
              class="text-red"
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-logout-variant"></v-icon>
              </template>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="config.userProfile.is_public_site_user_profile"
              @click="
                // loggedIn = false;
                router('login')
              "
            >
              <template v-slot:prepend>
                <v-icon icon="mdi-logout-variant"></v-icon>
              </template>
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      density="compact"
      app
      :style="
        loggedIn
          ? 'background-color: ' + this.config.siteConfig.toolbar_colour
          : 'display:none'
      "
      id="appBar"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        :color="titleTextColor"
        id="navMenu"
        title="Navigation"
      ></v-app-bar-nav-icon>
      <v-img
        class="mx-2"
        max-height="40"
        max-width="178"
        contain
        :src="config.siteConfig.logo"
        @click="router('map')"
        style="cursor: pointer"
        title="Map"
        alt="Company Logo"
      ></v-img>
      <v-row v-if="!isMobile" class="h1 pl-0 ml-0 text-left dynamicTextColour">
        <span
          v-if="this.config.customClientConfig.client_name"
          aria-label="Client Name"
          >{{ this.config.customClientConfig.client_name }}</span
        >
        <span v-else aria-label="Client Name">{{
          this.config.siteConfig.name
        }}</span>
      </v-row>
      <v-spacer />
      <v-row
        align="center"
        justify="end"
        class="mr-1"
        style="text-align: right"
      >
        <v-col v-if="reportDownloaded || reportDownloading">
          <v-row align-content="end" no-gutters>
            <v-col cols="9" class="pr-4 pt-1 text-white">
              <v-progress-linear
                v-if="!reportDownloaded"
                v-model="progressLinear"
                color="success"
                height="25"
                striped
              >
                <div
                  v-if="reportLoadingProgress < 100"
                  class="font-weight-medium"
                >
                  <strong
                    >Loading Themes {{ Math.ceil(progressLinear) }}%</strong
                  >
                </div>
                <div v-else class="font-weight-medium">
                  <strong>Aggregating {{ Math.ceil(progressLinear) }}%</strong>
                </div>
              </v-progress-linear>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="success"
                tile
                variant="elevated"
                @click="openReport"
                title="view report"
                aria-label="View Report"
                :disabled="!reportDownloaded"
              >
                {{ !reportDownloaded ? "Please Wait" : "View Report" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!--   Extra pages text buttons  -->
        <v-card-actions v-if="config.siteConfig.is_public_site">
          <div v-for="page in config.userConfig.modules" :key="page.route">
            <v-btn
              v-if="
                page.hasOwnProperty('show_at_header') && page.show_at_header
              "
              variant="text"
              @click="router(page.route)"
              :style="'color: ' + titleTextColor"
              class="pa-3"
            >
              {{ page.label }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-row>

      <!-- <v-toolbar-title>Application</v-toolbar-title> -->
    </v-app-bar>

    <v-main
      :style="!loggedIn ? 'padding:0px!important' : null"
      style="overflow: hidden"
    >
      <v-snackbar
        style="z-index: 100000001"
        v-model="snackbarVisible"
        :color="snackbar.colour"
        location="top"
        :timeout="snackbar.timeout"
        multi-line
      >
        <div class="text-h6 pb-3" style="width: 640px" :id="snackbar.id">
          <v-icon class="pr-3 mr-2" dark large>{{ snackbar.icon }}</v-icon
          >{{ snackbar.title }}
        </div>

        <p class="text-subtitle-1 text-white pb-2 ml-10" id="snackBarMessage">
          {{ snackbar.message }}
        </p>
        <p
          v-if="snackbar.timeout === -1 || snackbar.timeout === 0"
          style="width: 100%; text-align: right"
        >
          <v-btn
            class="text-white"
            tile
            variant="text"
            @click="snackbarVisible = false"
            aria-label="Close"
            >Close</v-btn
          >
        </p>
      </v-snackbar>
      <div class="progressBar" :style="!showLoader ? 'display:none;' : null">
        <v-progress-linear
          indeterminate
          color="#8DC8F1"
          height="6"
        ></v-progress-linear>
      </div>
      <div class="antiMasher" :style="!busy ? 'display:none' : null"></div>
      <router-view />
    </v-main>
  </v-app>
  <v-app v-else-if="isSuspendedOnProduction">
    <v-alert text prominent color="#2196F3">
      <h2 class="text-black" style="font-family: Roboto, sans-serif">
        Sorry but it appears this account is inactive.
      </h2>
      <h3 class="text-black" style="font-family: Roboto, sans-serif">
        Please contact support@ocsi.co.uk if you require more information.
      </h3>
    </v-alert>
  </v-app>
  <v-app v-else>
    <div class="loadingLI">
      <v-progress-circular :size="200" :width="3" color="#51627C" indeterminate
        >Loading Local Insight</v-progress-circular
      >
    </div>
  </v-app>
</template>

<script>
import ReportViewer from "@/components/ReportViewer";
import OcsiOnlyChip from "@/components/OcsiOnlyChip";
import { getURLParam } from "@/mixins/GetURLParam";
import { getCookie } from "@/mixins/GetCookie";
import { setCookie } from "@/mixins/SetCookie";
import { isMobileDevice } from "@/mixins/IsMobileDevice";

export default {
  computed: {
    showLoader: {
      get() {
        return this.showProgressLoader && this.busy;
      },
    },
    headerNavList: {
      get() {
        const filteredObj = {};
        for (const key in this.config.userConfig.modules) {
          if (
            // eslint-disable-next-line no-prototype-builtins
            this.config.userConfig.modules.hasOwnProperty(key) &&
            this.config.userConfig.modules[key]["show_at_header"] === true
          ) {
            filteredObj[key] = this.config.userConfig.modules[key];
          }
        }
        return filteredObj;
      },
    },
    helpCenterLink() {
      let link;
      const isAustralia = this.config.siteConfig.site_country === "aus";
      const isPublicSite = this.config.userProfile.is_public_site_user_profile;

      if (isAustralia) {
        link = isPublicSite
          ? "auslocalinsight.zendesk.com/hc/en-gb" // TODO: put aus-public site zendesk link if it's set
          : "auslocalinsight.zendesk.com/hc/en-gb";
      } else {
        link = isPublicSite
          ? "local-insight-public.zendesk.com/hc/en-gb"
          : "local-insight.zendesk.com/hc/en-gb";
      }

      return link;
    },
    progressLinear: {
      get() {
        return this.reportLoadingProgress < 100
          ? this.reportLoadingProgress
          : this.aggregationProgressPercentage;
      },
    },
    reportDownloading: {
      get() {
        return this.$store.state.reportDownloading;
      },
      set(value) {
        this.$store.commit("setReportDownloading", value);
      },
    },
    reportDownloaded: {
      get() {
        return this.$store.state.reportDownloaded;
      },
      set(value) {
        this.$store.commit("setReportDownloaded", value);
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    aggregationProgressPercentage: {
      get() {
        return this.$store.state.aggregationProgressPercentage;
      },
    },
    reportLoadingProgress: {
      get() {
        return this.$store.state.reportLoadingProgress;
      },
    },
    otherClientsCount: {
      get() {
        return this.config.userConfig.otherClients.length ? true : false;
      },
    },
    config: {
      get() {
        return this.$store.state.config;
      },
      set(value) {
        this.$store.commit("setConfig", value);
      },
    },
    busy: {
      get() {
        return this.$store.state.busy;
      },
      set(value) {
        this.$store.commit("setBusy", value);
      },
    },
    favicon: {
      get() {
        return this.config.siteConfig.favicon;
      },
    },
    uatEnvironment() {
      return process.env.VUE_APP_ENVIRONMENT === "uat";
    },
    isOcsiMember: {
      get() {
        return this.$store.state.isOcsiMember;
      },
      set(value) {
        this.$store.commit("setIsOcsiMember", value);
      },
    },
    isGroupAdmin: {
      get() {
        return this.$store.state.isGroupAdmin;
      },
      set(value) {
        this.$store.commit("setIsGroupAdmin", value);
      },
    },
    isPowerUser: {
      get() {
        return this.$store.state.isPowerUser;
      },
      set(value) {
        this.$store.commit("setIsPowerUser", value);
      },
    },
    isViewReports: {
      get() {
        return this.$store.state.isViewReports;
      },
      set(value) {
        this.$store.commit("setIsViewReports", value);
      },
    },
    isPublicUser: {
      get() {
        return this.$store.state.isPublicUser;
      },
      set(value) {
        this.$store.commit("setIsPublicUser", value);
      },
    },
  },
  data: () => ({
    isMobile: false,
    showProgressLoader: true,
    viewReportButtonLabel: false,
    snackbarVisible: false,
    adminEmail: null,
    adminPassword: null,
    adminDialog: false,
    switchClient: null,
    drawer: false,
    loggedIn: false,
    snackbar: {
      message: "this is a message this is a message this is a message",
      title: null,
      timeout: 4000,
      colour: null,
      icon: "mdi-alert-circle-outline",
    },
    pilotBugTrackerLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSdjJ99BpDNswfS7F5_lDZLX9BiyEqQGSckAdzFOvIaZmVEKQg/viewform",
    pilotFeedbackLink:
      "https://docs.google.com/forms/d/e/1FAIpQLSfxUVEBolGYSfXsfsuaVttn8qk6W8ypSTz-mkB3A4d34SYQpQ/viewform",
    isSuspendedOnProduction: false,
    titleTextColor: "white",
  }),
  components: {
    ReportViewer,
    OcsiOnlyChip,
  },
  watch: {
    favicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = this.config.siteConfig.favicon;
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.name + " | Local Insight" || "Local Insight";
      },
    },
  },
  mounted() {
    this.isMobile = isMobileDevice(); // should prolly make this global in the vuex store
    var sharedReport = getURLParam("report")
      ? "?report=" + getURLParam("report")
      : "";
    var savedMap = getURLParam("savedmap")
      ? "?savedmap=" + getURLParam("savedmap")
      : "";
    var param = sharedReport || savedMap;
    const elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
    // listen out for these
    this.emit.on("systemBusy", this.systemBusy);
    this.emit.on("systemMessage", this.displaySnackbar);
    this.emit.on("closeSystemMessage", this.closeSnackbar);
    this.emit.on("getClientAndUserConfig", this.getClientAndUserConfig);
    // else you will always bee redirected to the same public site
    if (getCookie("OI_PUBLIC_SITE")) {
      document.cookie =
        "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
    // get user details
    if (getCookie("BEARER_TOKEN")) {
      // set this
      this.$axios.defaults.headers.common["Authorization"] =
        "Bearer " + getCookie("BEARER_TOKEN");
      // call this
      this.getClientAndUserConfig(param);
    } else {
      this.loggedIn = false;
      // get the site config
      this.$axios
        .get("/get-site-config")
        .then(
          function (response) {
            this.isSuspendedOnProduction = false;
            // handle success
            // jog on!
            this.config = response.data;
            // set this flag
            this.$store.state.displayOnsSource = JSON.parse(
              process.env.VUE_APP_DISPLAY_ONS_SOURCE,
            )[this.config.siteConfig.site_country];
            // check if this is a public site
            if (this.config.siteConfig.is_public_site) {
              // set the bearer token cookie for 2 hours, add it to the axios auth header
              setCookie("BEARER_TOKEN", this.config.userConfig.token, 2);
              this.$axios.defaults.headers.common["Authorization"] =
                "Bearer " + getCookie("BEARER_TOKEN");
              // redirect to the members area and update the loggedIn flag
              this.router("map" + param);
              this.loggedIn = true;
            }
            if (typeof this.config.userProfile !== "undefined") {
              if (this.config.userProfile.is_public_site_user_profile) {
                setCookie("OI_PUBLIC_SITE", true, 2);
              }
            } else {
              document.cookie =
                "OI_PUBLIC_SITE=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            }
            this.busy = false;

            this.setToolbarTextColour();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            // if it's suspended on production then show the message
            if (error.response.data.errors[0].includes("suspended")) {
              this.isSuspendedOnProduction = true;
            }
            this.busy = false;
          }.bind(this),
        );

      // redirect to the login page if we're not already there
      if (this.$route.path !== "/login") {
        // forgotten password
        var fp = getURLParam("token");
        if (fp) {
          document.cookie =
            "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          this.router("login?token=" + fp);
        } else {
          this.router("login" + param);
        }
      }
    }
  },
  methods: {
    openReport() {
      this.systemBusy(true);
      if (!this.viewReportDialog) {
        this.viewReportDialog = true;
      }
      this.viewReportButtonLabel = false;
      this.systemBusy(false);
    },
    visitSite(url) {
      var port = location.port ? ":" + location.port : "";
      var redirectURL =
        location.protocol +
        "//" +
        process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
        url +
        port +
        "/#" +
        this.$route.path;
      window.location.replace(redirectURL);
    },
    switchToDifferentClient() {
      console.log(this.switchClient);
      this.$axios
        .get("/switch-client/" + this.switchClient.id)
        .then(
          function (response) {
            if (response.data) {
              var port = location.port ? ":" + location.port : "";
              var redirectURL =
                location.protocol +
                "//" +
                process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.switchClient["url"] +
                port +
                "/#" +
                this.$route.path;
              // some clients will have the same url
              if (redirectURL === window.location.href) {
                location.reload();
              } else {
                window.location.replace(redirectURL);
              }
            } else {
              this.emit.emit("systemMessage", {
                title: "Error!",
                message: "You do not have access to this client",
                timeout: 6000,
                colour: "error",
              });
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("invalid user details and or token" + error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              colour: "error",
            });
            this.logout();
          }.bind(this),
        );
    },
    router(path) {
      // if a path is http then redirect to that url
      if (path.indexOf("http") > -1) {
        window.open(path, "_blank");
        return;
      }
      // something different for public reports
      if (path === "public-reports") {
        if (this.$route.path !== "/map" && this.$route.path !== "/") {
          this.$router.push("/map");
          setTimeout(() => {
            document.getElementById("mapReportsButton").click();
          }, 500);
        } else {
          document.getElementById("mapReportsButton").click();
        }
      } else if (this.$route.path !== "/" + path) {
        this.$router.push("/" + path);
      }
      this.drawer = false;
    },
    getClientAndUserConfig(param) {
      this.$axios
        .get("/client-user-site-config")
        .then(
          function (response) {
            this.config = response.data;
            this.$store.state.displayOnsSource = JSON.parse(
              process.env.VUE_APP_DISPLAY_ONS_SOURCE,
            )[this.config.siteConfig.site_country];
            // catch this
            if (this.config.siteConfig.is_public_site) {
              // need to catch if this
              if (getURLParam("token")) {
                this.systemBusy(false);
                if (this.$route.path !== "/login") {
                  this.router("login" + param);
                }
                return;
              }
            }
            // set permission level
            this.setPermissionLevel();

            // set the toolbar text colour depending on the background brightness
            this.setToolbarTextColour();
            // make sure we are using the correct url..if not then they will be redirected to the one assciated to their user account
            if (
              process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.config.siteConfig.url !==
              window.location.hostname
            ) {
              // var port;
              var port = location.port ? ":" + location.port : "";
              var redirectURL =
                location.protocol +
                "//" +
                process.env.VUE_APP_ENVIRONMENT_SUB_DOMAIN +
                this.config.siteConfig.url +
                port +
                "/#/map" +
                param;
              window.location.replace(redirectURL);
            } else {
              // re(set) the bearer token cookie for another 2 hours
              setCookie("BEARER_TOKEN", getCookie("BEARER_TOKEN"), 2);
              // set these
              this.loggedIn = true;
              this.systemBusy(false);
              // redirect to the members area
              if (this.$route.path === "/login") {
                this.router("map" + param);
              }
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("invalid user details and or token" + error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              colour: "error",
            });
            this.logout();
          }.bind(this),
        );
    },
    // call this to log users out
    logout() {
      this.$store.commit("resetConfig");
      // set this
      this.systemBusy(true);
      // reset this
      this.loggedIn = false;
      // call this
      this.$axios
        .post("/logout")
        .catch(
          function (error) {
            // it would appear there is something wrong...probably an invalid bearer token
            console.error("(logout) invalid user details and or token" + error);
          }.bind(this),
        )
        .then(
          function () {
            // clear the bearer cookie and reload the app
            document.cookie =
              "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            this.$router.go();
          }.bind(this),
        );
    },
    setPermissionLevel() {
      switch (this.config.userConfig.permission_level) {
        // ocsi big boss
        case 1:
          this.isOcsiMember = true;
          this.isGroupAdmin = true;
          this.isPowerUser = true;
          this.isViewReports = true;
          this.isPublicUser = true;
          break;
        // group admin
        case 2:
          this.isGroupAdmin = true;
          this.isPowerUser = true;
          this.isViewReports = true;
          break;
        // power user
        case 3:
          this.isPowerUser = true;
          this.isViewReports = true;
          break;
        // view reports
        case 4:
          this.isViewReports = true;
          break;
        // public user
        case 5:
          this.isPublicUser = true;
          break;
      }
    },
    // for api calls..this shows the progress bar and adds a transparent layer over the screen to stop any button mashing
    systemBusy(state) {
      this.busy = state;

      // if it's an object, then read it
      if (typeof state === "object") {
        this.busy = state.busy;
        this.showProgressLoader = state.showProgressLoader;
      } else {
        this.busy = state;
        this.showProgressLoader = true;
      }
    },
    // shows messages on the screen
    displaySnackbar(info) {
      info.message =
        "message" in info && info["message"] !== null ? info.message : null;
      info.title =
        "title" in info && info["title"] !== null ? info.title : null;
      if (info.message !== null && info.title === null) {
        info.title = info.message;
        info.message = null;
      }
      info.timeout =
        "timeout" in info && info["timeout"] !== null ? info.timeout : 4000;
      info.colour =
        "colour" in info && info["colour"] !== null ? info.colour : null;

      this.snackbarVisible = false;
      this.snackbar.message = info.message;
      this.snackbar.title = info.title;
      this.snackbar.timeout = info.timeout;
      this.snackbar.colour = info.colour;
      this.snackbar.icon = "mdi-alert-circle-outline";
      this.snackbarVisible = true;
      this.snackbar.id = {
        green: "successSnackbar",
        success: "successSnackbar",
        error: "errorSnackbar",
        red: "errorSnackbar",
        warning: "warningSnackbar",
      }[info.colour];
    },
    closeSnackbar() {
      this.snackbarVisible = false;
    },
    setToolbarTextColour() {
      let backgroundColor = this.$store.state.config.siteConfig.toolbar_colour;
      let r = parseInt(backgroundColor.substr(1, 2), 16);
      let g = parseInt(backgroundColor.substr(3, 2), 16);
      let b = parseInt(backgroundColor.substr(5, 2), 16);

      // measure brightness
      let brightness = (r * 299 + g * 587 + b * 114) / 1000;

      // Determine the text color based on the brightness
      this.titleTextColor = brightness > 174 ? "black" : "white";

      // Create a new style element
      let style = document.createElement("style");
      style.type = "text/css";

      // Add a CSS rule to the style element
      style.innerHTML = `
        .dynamicTextColour,
        .text-report-loading,
        .v-overlay__content > .v-card-title,
        .v-dialog > .v-overlay__content > .v-card > .v-toolbar > .v-toolbar__content,
        .v-overlay__content > .v-card > .v-card-title {
          color: ${this.titleTextColor};
        }
      `;

      // Append the style element to the head of the document
      document.getElementsByTagName("head")[0].appendChild(style);
    },
  },
};
</script>

<style lang="css">
.h1 {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}
.app-report {
  border: 1px solid red !important;
}
.loadingLI {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* allow whitespace in snackbar messages */
#snackBarMessage {
  white-space: pre-wrap;
}

/* slow the spinners*/
.v-progress-circular--indeterminate .v-progress-circular__overlay {
  animation:
    progress-circular-dash 2.6s ease-in-out infinite,
    progress-circular-rotate 2.6s linear infinite !important;
}
.otherLinks {
  color: #0645ad;
  text-decoration: underline;
  font-size: 13px !important;
}
.app {
  background-color: #ebf2f6 !important;
}

#inspire {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.antiMasher {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000000000;
}
.progressBar {
  position: fixed;
  width: 100vw;
  z-index: 10000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h1 {
  color: #51627c;
  font-size: 20p;
  font-family: Rubik, Helvetica, sans-serif;
}
h2 {
  color: #51627c;
  font-size: 16p;
  font-family: Rubik, Helvetica, sans-serif;
}
h3 {
  color: #51627c;
  font-size: 14p;
  font-family: Rubik, Helvetica, sans-serif;
}
p {
  color: black;
  font-size: 11p;
  font-family: Rubik, Helvetica, sans-serif;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.min-accessible-size {
  font-size: 12pt;
}

/*this is for the get all button loader animation*/
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.moduleList .v-list-item--density-default.v-list-item--one-line {
  min-height: 45px;
}

.extraModuleList .v-list-item--density-default.v-list-item--one-line {
  min-height: 45px;
}
/* make the tip tap fullscreen mode appear under the LI header */
.vuetify-pro-tiptap-editor--fullscreen {
  padding: 50px !important;
}

.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 300px;
}

.progress {
  margin-top: 150px;
  width: 100%;
}

/* view of the disabled elevated button */
.v-btn--disabled.v-btn--variant-elevated {
  background-color: #acacac !important;
}
.v-btn--disabled.v-btn--variant-elevated .v-btn__append,
.v-btn--disabled.v-btn--variant-elevated .v-btn__prepend,
.v-btn--disabled.v-btn--variant-elevated .v-btn__content {
  color: #000000 !important;
}
.v-btn--disabled.v-btn--variant-elevated .v-btn__overlay,
.v-btn--disabled.v-btn--variant-flat .v-btn__overlay {
  opacity: 0.105384615 !important;
}

/* green elevated button colour */
.v-btn--elevated.bg-success {
  background-color: #265928 !important;
}

/* info alerts colour and blue elevated buttons */
.v-alert.bg-info,
.v-btn--elevated.bg-info {
  background-color: #0e5b99 !important;
}

/* Create a pseudo element for the gradient background before the text
   and stretch it to cover the whole help centre button. This is a 
   workaround for lack of support for transitions between linear-gradients */
#help-centre-item {
  position: relative;
  overflow: hidden;
}

#help-centre-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      90deg,
      rgba(141, 200, 241, 1) 0%,
      rgba(194, 149, 252, 1) 100%
    ),
    linear-gradient(white, white);
  opacity: 0.5;
  transition: opacity 0.3s ease;
  z-index: -1;
}

#help-centre-item:hover::before {
  background: linear-gradient(
      90deg,
      rgba(141, 200, 241, 1) 0%,
      rgba(194, 149, 252, 1) 100%
    ),
    linear-gradient(white, white);
  opacity: 1;
}
</style>
